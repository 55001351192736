// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pSJGN1ph{padding:0 0 40px}@media(min-width:667.98px){.pSJGN1ph{padding:0 0 100px}}.ESAxl2wW{white-space:nowrap}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"wrapper": "pSJGN1ph",
	"header": "ESAxl2wW"
};
module.exports = ___CSS_LOADER_EXPORT___;
