
export default {
  data: () => ({
    items: [],
  }),

  async fetch () {
    await this.fetchItems()
  },

  computed: {
    collapseItems () {
      return this.items.filter(i => i.question && i.answer).map(i => ({
        title: i.question,
        content: this.$fmt.md(i.answer),
      }))
    },
  },

  methods: {
    async fetchItems () {
      const [res, err] = await this.$api.faq.getFaq()
      if (err) console.error(err.response.data)
      else this.items = res
    },
  },
}
